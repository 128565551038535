import React from 'react';
import { useLocation  } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const path = location.pathname;
  const hasId = path.length>1;
    return (
      <>
      {hasId ? (
        <header id="header" className="fixed-top d-flex justify-content-center align-items-center header-transparent">

        <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link active" href="/">Home</a></li>
          <li><a className="nav-link active" href="/projectlist">Projects</a></li>
          {/* Add other links if needed */}
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
        
      </header>
      ) : (
        <header id="header" className="fixed-top d-flex justify-content-center align-items-center header-transparent header-temp-scrolled">

        <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="/#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="/#about">About</a></li>
          <li><a className="nav-link scrollto" href="/#resume">Resume</a></li>
          <li><a className="nav-link scrollto" href="/#services">Domain</a></li>
          <li><a className="nav-link scrollto" href="/#portfolio">Portfolio</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
    
      </header>
      )}
          
          </>
    );
};

export default Navbar;
