import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y,Autoplay  } from 'swiper/modules';
import {Img} from 'react-image';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const ProjectDetail = () => {
    const { id } = useParams();
    const [projectList,setProjects] = useState([]);
    
    useEffect(() => {
      fetch('/Portfolio.json')
        .then((response) => response.json())
        .then((data) => {
          setProjects(data);
        })

        .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const project = projectList.find((p) => p.Id === parseInt(id));

    

    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <main id="main">
          <section id="portfolio-details" className="portfolio-details">
      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-12">
          <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay ]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
    >
      {project.ProjectImages.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className='image-container'>
                    <Img src={image} alt={`Project Slide ${index + 1}`} />
                    </div>
                    
                  </SwiperSlide>
                ))}
      </Swiper>
            
          </div>

          
        </div>
        <div className="row gy-4">
        <div className="col-lg-8">
        <div className="portfolio-description">
              <h2>Project Overview</h2>
              {project.ProjectDescription.map((desc,index)=>(<p key={index} dangerouslySetInnerHTML={{ __html: desc }}></p>))}
              
            </div>
        </div>
        <div className="col-lg-4">
            <div className="portfolio-info">
              <h3>Project information</h3>
              <ul>
                <li><strong>Name</strong>: {project.ProjectName}</li>
                <li><strong>Technology</strong>: {project.Technology}</li>
                <li><strong>Key Features</strong>: {project.KeyFeature}</li>
                <li><strong>Project Duration</strong>: {project.Duration}</li>
                <li><strong>Project URL</strong>: <a href={project.Url}>{project.Url}({project.Status})</a></li>
                <li><strong>Team Size</strong>: {project.TeamSize}</li>
                <li><strong>My Role</strong>: <ul>
                {project.MyRole.map((role,index)=>(<li key={index}>{role}</li>))}
                </ul>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
        
      </div>
    </section>
</main>

    );
};

export default ProjectDetail;
