import React, { useEffect, useState } from 'react';
import {Img} from 'react-image';
import Isotope from 'isotope-layout';

const ProjectList = ()=>{
    const [projectList,setProjects] = useState([]);
    // state for storing the isotope object, with an initial value of null
const [isotope, setIsotope] = React.useState(null);
// state for storing the filter keyword, with an initial value of *, which matches everything
const [filterKey, setFilterKey] = React.useState('*');
    useEffect(() => {
      fetch('/Portfolio.json')
        .then((response) => response.json())
        .then((data) => {
          setProjects(data);
        })

        .catch((error) => console.error('Error fetching data:', error));
    }, []);

    React.useEffect(() => {
        setIsotope(
          new Isotope('.portfolio-list-container', {
            // filter-container: className of the parent of the isotope elements
            itemSelector: '.portfolio-list-item', // filter-item: className of the isotope elements
            
          })
        );
      }, [projectList]);

      React.useEffect(() => {
        if (isotope) {
          // sanity check
          filterKey === '*'
            ? isotope.arrange({ filter: `*` })
            : isotope.arrange({ filter: `.${filterKey}` });
        }
      }, [isotope, filterKey]);

      const handleFilterClick = (filter) => {
        setFilterKey(filter);
      };

return (
    <div id="main" style={{ marginTop: '20px' }}>
    <section id="portfolio" className="portfolio">
    <div className="container">
<ul id="portfolio-flters" className="d-flex justify-content-center">
          <li data-filter="*" className={`${filterKey === '*' ? 'filter-active' : ''}`}
          onClick={() => handleFilterClick('*')}>All</li>
         <li data-filter=".filter-web-form" className={`${filterKey === 'filter-web-form' ? 'filter-active' : ''}`}
          onClick={() => handleFilterClick('filter-web-form')}>Web-Form</li>
        <li data-filter=".filter-mvc" className={`${filterKey === 'filter-mvc' ? 'filter-active' : ''}`}
          onClick={() => handleFilterClick('filter-mvc')}>ASP.Net MVC</li>
        <li data-filter=".filter-core" className={`${filterKey === 'filter-core' ? 'filter-active' : ''}`}
          onClick={() => handleFilterClick('filter-core')}>Asp.Net Core</li>
        </ul>
    <div className="row portfolio-list-container">
          {projectList.map((project, index)=> 
        <div key={project.Id} style={{ minHeight: '280px' }} className={`col-lg-4 col-md-6 portfolio-list-item filter-${project.ProjectType}  mb-4`}>
        <a href={`/project/${project.Id}`} title="More Details">
        <div className="card h-100">
        
        <div className="portfolio-img"><Img src={project.ProjectTitleImage} className="img-fluid" alt="" /></div>
        <div className="card-footer">
        <div className="portfolio-info">
          <h4>{project.ProjectName}</h4>
          <p>{project.ProjectType}</p>
          </div>
        </div>
        </div>
        </a>
      </div>)}
          
          </div>
          </div>
    </section>
    </div>

);

};

export default ProjectList;