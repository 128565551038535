import React from "react";
import {Img} from 'react-image';

const Portfolio = () => {
  return (
    <div class="container">

        <div class="section-title">
          <span>My Portfolio</span>
        <h2>My Portfolio</h2>
        <p>Here are the list of projects that I worked on...</p>
        </div>

        <ul id="portfolio-flters" class="d-flex justify-content-center">
          <li data-filter="*" class="filter-active">All</li>
         <li data-filter=".filter-web-form">Web-Form</li>
        <li data-filter=".filter-mvc">ASP.Net MVC</li>
        <li data-filter=".filter-core">Asp.Net Core</li>
        </ul>

        <div class="row portfolio-container">

          <div class="col-lg-4 col-md-6 portfolio-item filter-web-form">
            <div class="portfolio-img"><Img src="assets/img/portfolio/mff-home.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Move For Free</h4>
			  <p>Mff</p>
              <a href="assets/img/portfolio/mff-home.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="/project/1" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web-form">
            <div class="portfolio-img"><Img src="assets/img/portfolio/costadvisor-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Cost Advisor</h4>
			  <p>Cost Advisor</p>
              <a href="assets/img/portfolio/costadvisor-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="/project/2" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-core">
            <div class="portfolio-img"><Img src="assets/img/portfolio/hicollab-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>UAM</h4>
			  <p>App</p>
              <a href="assets/img/portfolio/hicollab-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="/project/3" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/mdiss-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>MDISS</h4>
              <p>Travel Portal</p>
              <a href="assets/img/portfolio/mdiss-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="Card 2"><i class="bx bx-plus"></i></a>
              <a href="/project/4" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-core">
            <div class="portfolio-img"><Img src="assets/img/portfolio/firststep-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>First Step</h4>
			  <p>First Step</p>
              <a href="assets/img/portfolio/firststep-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="/project/5" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-core">
            <div class="portfolio-img"><Img src="assets/img/portfolio/mountainhomestay-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Mountain homestay</h4>
			  <p>Mountain homestay</p>
              <a href="assets/img/portfolio/mountainhomestay-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="/project/6" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-core">
            <div class="portfolio-img"><Img src="assets/img/portfolio/enseblehp-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Ensemble EIQ</h4>
			  <p>Ensemble EIQ</p>
              <a href="assets/img/portfolio/enseblehp-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="/project/7" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/messageboard-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Message Board</h4>
              <p>Message Board</p>
              <a href="assets/img/portfolio/messageboard-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="Card 2"><i class="bx bx-plus"></i></a>
              <a href="/project/8" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/hischeduling-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Hi-Scheduling</h4>
			  <p>Hi-Scheduling</p>
              <a href="assets/img/portfolio/hischeduling-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="/project/9" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/himtt-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>HIMTT</h4>
			  <p>HIMTT</p>
              <a href="assets/img/portfolio/himtt-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="/project/10" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/konnektiveAPI-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Konnektive APIs Intigration</h4>
			  <p>Konnektive APIs Intigration</p>
              <a href="assets/img/portfolio/konnektiveAPI-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="/project/11" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-core">
            <div class="portfolio-img"><Img src="assets/img/portfolio/recognizegood-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Recognize Good</h4>
			  <p>Build A Coin</p>
              <a href="assets/img/portfolio/recognizegood-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="/project/13" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
             </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-mvc">
            <div class="portfolio-img"><Img src="assets/img/portfolio/prepaid-title.jpg" class="img-fluid" alt="" /></div>
            <div class="portfolio-info">
              <h4>Prepaid</h4>
			  <p>Prepaid</p>
              <a href="assets/img/portfolio/prepaid-title.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox preview-link" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="/project/14" class="details-link" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>

        </div>

      </div>
  );
};

export default Portfolio;
