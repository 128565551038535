import React ,{ useState, useEffect }from 'react';
import Portfolio from './Portfolio';


const Home = () => {
    const [items, setItems] = useState([]);
  
    useEffect(() => {
      fetch('/Portfolio.json')
        .then((response) => response.json())
        .then((data) => {
          setItems(data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          
        });
    }, []);

    return (
        <main id="main">
            <section id="hero">
                <div className="hero-container">
                    <h1>Parvind Kumar Singh</h1>
                    <h2>I'm a Full Stack Dot Net Developer Over 5+ yrs Of Experience</h2>
                    <a href="#about" className="btn-scroll scrollto" title="Scroll Down"><i className="bx bx-chevron-down"></i></a>
                </div>
            </section>
            <section id="about" className="about">
                <div className="container">
                    <div className="section-title">
                        <span>About Me</span>
                        <h2>About Me</h2>
                        <p>Here are my professional as well as skills details</p>
                    </div>
                    <div className="row">
                        <div className="image col-lg-4 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
                        <div className="col-lg-8 d-flex flex-column align-items-stretch">
                            <div className="content ps-lg-4 d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <ul>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Name:</strong> <span>Parvind Kumar Singh</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Website:</strong> <span>iamparvind.in</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Phone:</strong> <span>+91 9628591129</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>City:</strong> <span>Ballia, U.P.</span></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6">
                                        <ul>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Age:</strong> <span>31</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>B-Tech(Computer Science)</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Email:</strong> <span>singhparvind1993@gmail.com</span></li>
                                            <li><i className="bi bi-chevron-right"></i> <strong>Marital status:</strong> <span>Married</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row mt-n4">
                                    {/* <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                                        <div className="count-box">
                                            <i className="bi bi-emoji-smile" style={{ color: '#20b38e' }}></i>
                                            <span className="purecounter"></span>
                                            <p><strong>Happy Clients</strong> consequuntur voluptas nostrum aliquid ipsam architecto ut.</p>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                                        <div className="count-box">
                                            <i className="bi bi-briefcase" style={{ color: '#8a1ac2' }}></i>
                                            <span data-purecounter-start="0" data-purecounter-end={items.length} data-purecounter-duration="0" className="purecounter"></span>
                                            <p><strong>Projects</strong> I do {items.length} projects from diffrent domain</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                                        <div className="count-box">
                                            <i className="bi bi-clock" style={{ color: '#2cbdee' }}></i>
                                            <span data-purecounter-start="0" data-purecounter-end="5" data-purecounter-duration="0" className="purecounter"></span>
                                            <p><strong>Years of experience</strong> I have 5+ yrs of relivent experience in dot net development</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-5 d-md-flex align-items-md-stretch">
                                        <div className="count-box">
                                            <i className="bi bi-award" style={{ color: '#ffb459' }}></i>
                                            <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="0" className="purecounter"></span>
                                            <p><strong>Awards</strong> I got two time employee of the month rewards from my previous company.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="skills-content ps-lg-4">
                                <div className="progress">
                                    <span className="skill">ASP.NET Web Form <i className="val">2 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">ASP.NET MVC <i className="val">5 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">ASP.NET CORE <i className="val">5 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">Azure <i className="val">3 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">JQuery <i className="val">5 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">Angular <i className="val">2 yrs</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className="progress">
                                    <span className="skill">ReactJs <i className="val">1 yr</i></span>
                                    <div className="progress-bar-wrap">
                                        <div className="progress-bar" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="resume" className="resume">
                <div className="container">
                    <div className="section-title">
                        <span>My Resume</span>
                        <h2>My Resume</h2>
                        <p>Let's dive into my career path...</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="resume-title">Summary</h3>
                            <div className="resume-item pb-0">
                                <h4>Parvind Kumar Singh</h4>
                                <p><em>Highly skilled in ASP.NET MVC and ASP.NET Core, with a proven track record in designing, developing, and maintaining robust web applications. Proficient in C#, JavaScript, SQL, and cloud services like Azure and AWS, with strong expertise in Agile methodologies and RESTful API development.</em></p>
                                <ul>
                                    <li>Garwar, Ballia, UP</li>
                                    <li>(123) 456-7891</li>
                                    <li>singhparvind1993@gmail.com</li>
                                </ul>
                            </div>
                            <h3 className="resume-title">Education</h3>
                            <div className="resume-item">
                                <h4>B-Tech(Computer Science)</h4>
                                <h5>2012 - 2016</h5>
                                <p><em>UPTU, Lucknow, UP</em></p>
                                <p>60%</p>
                            </div>
                            <div className="resume-item">
                                <h4>Intermediate</h4>
                                <h5>2010 - 2011</h5>
                                <p><em>KVPL, Meerut, UP</em></p>
                                <p>61%</p>
                            </div>
                            <div className="resume-item">
                                <h4>High School</h4>
                                <h5>2009 - 2010</h5>
                                <p><em>KVPL, Meerut, UP</em></p>
                                <p>74%</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="resume-title">Professional Experience</h3>
                            <div className="resume-item">
                                <h4>Software Engineer</h4>
                                <h5>May, 2023 - July, 2024</h5>
                                <p><em>Thinkbridge, Pune, Maharastra </em></p>
                                <ul>
                                    <li>Analyzing and developing new features while maintaining the application</li>
                                    <li>Handling User Acceptance Testing (UAT) backend releases</li>
                                    <li>Performing unit testing to ensure code quality</li>
                                    <li>Addressing production support tickets as needed</li>
                                </ul>
                            </div>
                            <div className="resume-item">
                                <h4>Dot Net Developer</h4>
                                <h5>Dec, 2018 - March, 2023</h5>
                                <p><em>SCO-17, Chandigarh</em></p>
                                <ul>
                                    <li>Client handling and relationship management</li>
                                    <li>Managing a 4-member team and assigning tasks effectively</li>
                                    <li>Responsible for coordinating and overseeing releases in both testing and production environments</li>
                                    <li>Code review</li>
                                    <li>Writing code and developing unit test cases</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="services" className="services">
                <div className="container">
                    <div className="section-title">
                        <span>Domain</span>
                        <h2>Domain</h2>
                        <p>As an software developer, I have worked extensively on a variety of projects across multiple domains.</p>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="bx bx-file"></i></div>
                                <h4 className="title">Education</h4>
                                <p className="description">Developed interactive school management systems for educational institutions</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="bi bi-send-fill"></i></div>
                                <h4 className="title">Travel</h4>
                                <p className="description">Built user-friendly tools that allow travelers to plan detailed itineraries, Designed admin interfaces for tour operators to create, update, and manage tours</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="bi bi-clipboard-plus-fill"></i></div>
                                <h4 className="title">Health Care</h4>
                                <p className="description">Built robust patient management systems that streamline appointment scheduling, medical records, and billing processes.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                            <div className="icon-box">
                                <div className="icon"><i className="bi bi-currency-dollar"></i></div>
                                <h4 className="title">Finance</h4>
                                <p className="description">Focused on implementing features such as account management, transaction tracking, and fraud detection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="portfolio" className="portfolio">
                <Portfolio items={items}/>
            </section>
            <section id="contact" className="contact">
                <div className="container">
                    <div className="section-title">
                        <span>Contact Me</span>
                        <h2>Contact Me</h2>
                        <p>Here are my contact details in case you want to connect with me...</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="info-box">
                                        <i className="bx bx-share-alt"></i>
                                        <h3>Social Profiles</h3>
                                        <div className="social-links">
                                            <a href="https://github.com/parvind27" className="twitter"><i className="bi bi-github"></i></a>
                                            {/* <a href="dummy.com" className="facebook"><i className="bi bi-facebook"></i></a>
                                            <a href="dummy.com" className="instagram"><i className="bi bi-instagram"></i></a>
                                            <a href="dummy.com" className="google-plus"><i className="bi bi-skype"></i></a> */}
                                            <a href="https://www.linkedin.com/in/parvind-singh-10118396/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box mt-4">
                                        <i className="bx bx-envelope"></i>
                                        <h3>Email Me</h3>
                                        <p>singhparvind1993@gmail.com</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box mt-4">
                                        <i className="bx bx-phone-call"></i>
                                        <h3>Call Me</h3>
                                        <p>+91 9519 1518 95</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form action="forms/contact.php" method="post" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                                </div>
                                <div className="my-3">
                                    <div className="loading">Loading</div>
                                    <div className="error-message"></div>
                                    <div className="sent-message">Your message has been sent. Thank you!</div>
                                </div>
                                <div className="text-center"><button type="submit">Send Message</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Home;